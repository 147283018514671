import { EntityRepository } from '../data/EntityRepository';
import { SchemeRegistry } from './SchemeRegistry';

export type SerializedModelFile = { filename: string; content?: string; fileType: string; entityId?: string };

export class ModelSerializer {
  private repository: EntityRepository;

  constructor(repository: EntityRepository) {
    this.repository = repository;
  }

  public serialize(): SerializedModelFile[] {
    const rules = SchemeRegistry.getSerializationRules();
    const serializedFiles: SerializedModelFile[] = [];
    const rootFolders = new Set<string>();

    this.repository.list().forEach((entity) => {
      const matchingRules = rules.filter((rule) => rule.match(entity));

      if (matchingRules.length === 0) {
        return;
      }

      matchingRules.forEach((rule) => {
        const entityOutput = rule.serialize(entity);

        entityOutput.forEach((file) => {
          const rootFolder = file.filename.split('/')[0] + '/';

          if (!rootFolders.has(rootFolder)) {
            serializedFiles.push({ filename: rootFolder, fileType: 'folder' });
            rootFolders.add(rootFolder);
          }
          serializedFiles.push({
            filename: file.filename,
            content: file.content,
            fileType: file.fileType,
            entityId: file.entityId ?? entity.id,
          });
        });
      });
    });

    return serializedFiles.sort((a, b) => a.filename.localeCompare(b.filename));
  }
}
