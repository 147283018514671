import { EntityBase } from '../entities/EntityBase';
import { ConstructBase } from '../entities/constructs/ConstructBase';
import { AssetBase } from '../entities/assets/AssetBase';
import { Attachment } from '../entities/assets/Attachment';
import { DetailsElement } from './DetailsElement';
import { ScriptBase } from '../entities/scripts/ScriptBase';
import { z } from 'zod';
import { EntityClassRegistry } from '../entities/EntityClassRegistry';
import { EntityTypeRegistry } from '../entities/EntityTypeRegistry';
import {
  Asset,
  Construct,
  ConstructShape,
  EntityType,
  FileConfig,
  Scheme,
  Script,
  ViewMode,
} from 'narrative-studio-sdk';
import { scriptBaseSchema } from '../entities/scripts/ScriptBaseSchema';
import { ScriptConfig } from '../entities/scripts/ScriptConfig';
import { SerializationRule } from 'narrative-studio-sdk/dist/types/scheme/SerializationRule';
import { preloadImage } from '../utils';

type UserDefinedEntity = Construct | Asset | Script;

export interface DynamicConstruct {
  type: EntityType | string;
  label: string;
  description?: string;
  backgroundColor: string;
  textColor?: string;
  shape: ConstructShape;
  detailsPane?: DetailsElement[];
}

type EntityBaseConstructor = new (
  id: string,
  name: string,
  parent: EntityBase | undefined,
  position: { x: number; y: number },
  scopes: string[],
  attributes: any,
  width: number,
  height: number,
  isVisible: boolean,
  zIndex: number,
) => ConstructBase | AssetBase | ScriptBase;

export class SchemeRegistry {
  constructor() {}

  private static _serializeRules: SerializationRule[] = [];
  public static setCurrentScheme(schemes: Scheme[]): void {
    EntityClassRegistry.reset();
    schemes.forEach((scheme: Scheme) => {
      const construct = scheme.categories.flatMap((category) => category.constructs ?? []);
      const assets = scheme.categories.flatMap((category) => category.assets ?? []);
      const scripts = scheme.categories
        .flatMap((category) => category.constructs?.flatMap((construct) => construct.script) ?? [])
        .filter((script): script is Script => script !== undefined);
      this.registerEntities({ constructs: construct, assets: assets, scripts: scripts });
    });
  }

  public static registerSerializationRules(rules: SerializationRule[]): void {
    this._serializeRules = rules;
  }

  public static getSerializationRules(): SerializationRule[] {
    return this._serializeRules;
  }

  public static registerEntities(entities: { constructs: Construct[]; assets: Asset[]; scripts: Script[] }): void {
    if (entities.scripts) {
      entities.scripts.forEach((scriptDef) => {
        const dynamicEntityClass = this.createEntityClass(scriptDef, ScriptBase);
        this.registerDynamicEntity(scriptDef.type, dynamicEntityClass);
      });
    }
    if (entities.constructs) {
      entities.constructs.forEach((constructDef) => {
        const dynamicEntityClass = this.createEntityClass(constructDef, ConstructBase);
        EntityClassRegistry.register(constructDef.type, dynamicEntityClass);
        EntityTypeRegistry.register(constructDef.type);
        this.registerDynamicEntity(constructDef.type, dynamicEntityClass);
      });
    }
    if (entities.assets) {
      entities.assets.forEach((attachmentDef) => {
        const dynamicEntityClass = this.createEntityClass(attachmentDef, AssetBase);
        this.registerDynamicEntity(attachmentDef.type, dynamicEntityClass);
      });
    }
  }

  private static registerDynamicEntity(entityType: string, dynamicEntityClass: EntityBaseConstructor) {
    EntityClassRegistry.register(entityType, dynamicEntityClass);
    EntityTypeRegistry.register(entityType);
  }

  public static deRegisterEntities(entityTypes: string[]) {
    entityTypes.forEach((entityType) => {
      EntityClassRegistry.deregister(entityType);
      EntityTypeRegistry.deregister(entityType);
    });
  }

  /**
   * This is needed to display added entities after an app is removed
   */
  public static createDynamicFallbackEntity(data: any): EntityBaseConstructor {
    if (!data.__dynamic) {
      throw new Error('Unable to create dynamic entity as entity is not marked as dynamic', data);
    }
    if (data.__baseType === 'Construct') {
      return this.CreateConstructClass(data);
    } else if (data.__baseType === 'Asset') {
      return this.CreateAssetClass(data);
    }
    throw new Error('Unsupported entity for dynamic fallback');
  }

  private static createEntityClass(
    entity: UserDefinedEntity,
    baseClass: typeof ConstructBase | typeof AssetBase | typeof ScriptBase,
  ): EntityBaseConstructor {
    const entityType = entity.type;
    if (baseClass === ConstructBase) {
      return this.CreateConstructClass(entity as Construct);
    } else if (baseClass === AssetBase) {
      Attachment.registerAssetIcon({
        assetType: entityType,
        url: (entity as Asset).icon,
      });
      preloadImage((entity as Asset).icon);
      Attachment.registerDataSource({
        assetType: entityType,
        dataSource: (entity as Asset)?.dataSource ?? '',
      });
      return this.CreateAssetClass(entity as Asset);
    } else if (baseClass === ScriptBase) {
      return this.createScriptClass(entity as Script);
    }
    throw new Error('Unsupported entity base class');
  }

  private static CreateAssetClass(entity: Asset, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    const schema = AssetBase.baseSchema.extend({
      type: z.string().optional().default(entity.type),
      detailsPane: z
        .array(
          z.object({
            label: z.string(),
            type: z.string().optional(),
            properties: z.record(z.any()).optional(),
          }),
        )
        .optional(),
    });
    return class extends AssetBase {
      public type = entity.type;
      public __dynamic = true;
      public __baseType = 'Asset';
      private _detailsPane = detailsPane;

      public get fileConfig(): FileConfig {
        return entity.fileConfig ?? super.fileConfig;
      }

      static parse(data: unknown): AssetBase {
        return AssetBase.parseBase.call(this, data, schema);
      }

      public isValid(): boolean {
        return schema.safeParse(this).success;
      }
    };
  }

  private static CreateConstructClass(entity: Construct, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    const hasScript = entity.script !== undefined;
    let schema = ConstructBase.baseSchema.extend({
      type: z.string().optional().default(entity.type),
      detailsPane: z
        .array(
          z.object({
            label: z.string(),
            type: z.string().optional(),
            properties: z.record(z.any()).optional(),
          }),
        )
        .optional(),
    });
    if (hasScript) {
      schema = schema.extend({
        script: z.lazy(() => scriptBaseSchema),
      });
    }
    if (entity.shape === 'square') {
      schema = schema.extend({
        width: z.number().default(80),
      });
    }
    return class extends ConstructBase {
      static schema = schema;
      style = {
        backgroundColor: entity?.style?.backgroundColor ?? 'white',
        textColor: entity?.style?.textColor ?? 'black',
        borderColor: entity?.style?.borderColor,
        borderWidth: entity?.style?.borderWidth,
      };

      public get fileConfig(): FileConfig {
        return entity.fileConfig ?? super.fileConfig;
      }

      public get visibleInMode(): ViewMode {
        return entity.visibleInModes ?? super.visibleInMode;
      }

      public type = entity.type;
      public __dynamic = true;
      get scriptType(): string | undefined {
        return hasScript ? entity.script?.type : undefined;
      }
      public __baseType = 'Construct';
      private _detailsPane = detailsPane;
    };
  }

  private static createScriptClass(entity: Script, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    return class extends ScriptBase {
      public type = entity.type;
      public __dynamic = true;
      public __baseType = 'Script';
      private _detailsPane = detailsPane;

      static version = '2.0.0';

      get config(): ScriptConfig {
        return {
          frameGroups: entity.frameGroups ?? [],
          laneGroups: entity.laneGroups ?? [],
          get defaultFrameWidth(): number {
            return 200;
          },
          get defaultLaneHeight(): number {
            return 200;
          },
          get defaultLaneColor(): string {
            return '#F8F8F8';
          },
        };
      }
    };
  }
}
