import { EntityType } from './EntityType';
import { NarrativeScript } from './scripts/NarrativeScript';
import { Moment } from './constructs/Moment';
import { Interface } from './constructs/Interface';
import { Action } from './constructs/Action';
import { Narrative } from './constructs/Narrative';
import { Command } from './constructs/Command';
import { Data } from './constructs/Data';
import { Event } from './constructs/Event';
import { Process } from './constructs/Process';
import { Constraints } from './constructs/Constraints';
import { ExternalSystem } from './constructs/ExternalSystem';
import { Resolver } from './constructs/Resolver';
import { ReadModel } from './constructs/ReadModel';
import { Projection } from './constructs/Projection';
import { Gateway } from './constructs/Gateway';
import { Attachment } from './assets/Attachment';
import { Actor } from './assets/Actor';
import { Spec } from './assets/Spec';
import { Doc } from './assets/Doc';
import { Schema } from './assets/Schema';
import { Query } from './assets/Query';
import { Thread } from './threads/Thread';
import { Comment } from './threads/Comment';
import { Capability } from './scripts/Capability';
import { ActionScript } from './scripts/ActionScript';
import { Label } from './assets/Label';
import { Filter } from './Filter';
import { Edge } from './transitions/Edge';
import { Upload } from './assets/Upload';
import { Preview } from './assets/Preview';
import { GqlOperation } from './gql-entities/GqlOperation';
import { GqlField } from './gql-entities/GqlField';
import { EntityBase } from './EntityBase';

type EntityBaseConstructor = new (...args: any[]) => EntityBase;

export class EntityClassRegistry {
  private static registry: Partial<Record<EntityType, EntityBaseConstructor>>;
  private static initialized = false;

  private static initializeEntityClassRegistry() {
    if (!this.initialized) {
      this.registry = {
        [EntityType.NarrativeScript]: NarrativeScript,
        [EntityType.Moment]: Moment,
        [EntityType.Interface]: Interface,
        [EntityType.Action]: Action,
        [EntityType.Narrative]: Narrative,
        [EntityType.Command]: Command,
        [EntityType.Data]: Data,
        [EntityType.Event]: Event,
        [EntityType.Process]: Process,
        [EntityType.Constraints]: Constraints,
        [EntityType.ExternalSystem]: ExternalSystem,
        [EntityType.Resolver]: Resolver,
        [EntityType.ReadModel]: ReadModel,
        [EntityType.Projection]: Projection,
        [EntityType.Gateway]: Gateway,
        [EntityType.Attachment]: Attachment,
        [EntityType.Actor]: Actor,
        [EntityType.Spec]: Spec,
        [EntityType.Doc]: Doc,
        [EntityType.Schema]: Schema,
        [EntityType.Query]: Query,
        [EntityType.Edge]: Edge,
        [EntityType.Thread]: Thread,
        [EntityType.Comment]: Comment,
        [EntityType.Upload]: Upload,
        [EntityType.Capability]: Capability,
        [EntityType.ActionScript]: ActionScript,
        [EntityType.Label]: Label,
        [EntityType.Filter]: Filter,
        [EntityType.Preview]: Preview,
        [EntityType.GqlOperation]: GqlOperation,
        [EntityType.GqlField]: GqlField,
      };
      this.initialized = true;
    }
  }

  public static register(type: EntityType | string, constructor: EntityBaseConstructor): void {
    this.initializeEntityClassRegistry();
    this.registry[type] = constructor;
  }

  public static deregister(type: string): void {
    this.initializeEntityClassRegistry();
    delete this.registry[type];
  }

  public static reset(): void {
    this.initialized = false;
    this.initializeEntityClassRegistry();
  }

  public static entries() {
    return this.registry;
  }

  public static getConstructor(type: string) {
    this.initializeEntityClassRegistry();
    return this.registry[type];
  }
}
